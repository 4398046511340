import React from 'react'
import Router from './router'
import egisTheme from '@/assets/js/materialUi/egisTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import AppBar from 'components/layout/appBar'
import Footer from 'components/layout/footer'
import { Container } from '@material-ui/core'

function App() {
  return (
    <ThemeProvider theme={egisTheme}>
      <AppBar />
      <Container>
        <Router></Router>
      </Container>
      <Footer />
    </ThemeProvider>
  )
}

export default App
