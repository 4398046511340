import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import routes from './routes'
import AuthMiddleware from './authMiddleware'

function RouterComponent() {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  )
}

export default RouterComponent

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <AuthMiddleware route-props={props} route={route} />
      )}
    />
  )
}
