import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#09212c' },
    secondary: { main: '#abc022' },
    accent: { main: '#97b8bb' },
    success: { main: '#abc022' },
    info: { main: '#009aa6' },
    warning: { main: '#F4A41D' },
    error: { main: '#C8313F' },
  },
})

export default theme
