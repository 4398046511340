import React, { Component } from 'react'
import $axios from '@/assets/js/axios/api'

export default class authMiddleware extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: null }
  }
  // Do asynchronous action here
  async componentDidMount() {
    try {
      const { status } = await $axios.get('/api/user/is-auth', {
        params: { __noCache: true },
        validateStatus: function (status) {
          return status < 500 // Reject only if the status code is greater than or equal to 500
        },
      })
      if (status === 401) {
        this.setState({ redirect: true })
        const loginLocation = new URL(process.env.REACT_APP_LOGIN_URL)
        let hasSearchParams = false
        if (loginLocation.hash) {
          const mockHashURL = new URL(
            loginLocation.hash.slice(1),
            'http://localhost'
          )
          hasSearchParams = !!mockHashURL.search
        } else {
          hasSearchParams = !!loginLocation.search
        }
        const mockHashURL = new URL('http://localhost')
        mockHashURL.searchParams.set('next', window.location.href)
        const searchNext = `${
          hasSearchParams ? '&' : '?'
        }${mockHashURL.search.slice(1)}`
        window.location.assign(`${loginLocation.href}${searchNext}`)
      } else {
        this.setState({ redirect: false })
      }
    } catch (err) {
      // error handling
    }
  }

  render() {
    const { redirect } = false
    if (redirect === null) return <div>checking auth ...</div>
    return redirect ? (
      <div>will redirect</div>
    ) : (
      <this.props.route.component
        {...this.props.routeProps}
        routes={this.props.route.routes}
      />
    )
  }
}
