import Services from 'components/services'
import React, { Component } from 'react'
import $axios from '@/assets/js/axios/api'
import { Box } from '@material-ui/core'

export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = { services: [] }
  }

  async componentDidMount() {
    try {
      const {
        data: { services },
      } = await $axios.get(process.env.REACT_APP_SERVICES_PATH)
      this.setState({ services })
    } catch (error) {
      // do nothing
    }
  }

  render() {
    return (
      <Box display="flex" justifyContent="center" py={5}>
        <Services services={this.state.services} />
      </Box>
    )
  }
}

export default Home
