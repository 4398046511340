import logo from '@/assets/images/logo/egis.png'
import Box from '@material-ui/core/Box'
import React from 'react'

export default function egisFooter() {
  return (
    <Box bottom={0} position="fixed" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        bgcolor="primary.main"
        justifyContent="center"
        color="primary.contrastText"
      >
        <Box>&copy; {new Date().getFullYear()} —</Box>
        <Box px={1}>
          <a href="https://www.egis.fr/">
            <img src={logo} alt="egis" />
          </a>
        </Box>
        <Box>— Tous droits réservés</Box>
      </Box>
    </Box>
  )
}
