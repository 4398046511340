import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import LaunchIcon from '@material-ui/icons/Launch'

const Service = ({ service }) => {
  function openLandingPage() {
    window.open(service.landingPageURL, '_newtab')
  }
  function openDocs() {
    window.open(service.docsURL, '_newtab')
  }
  return (
    <Card>
      <CardActionArea onClick={openLandingPage}>
        <CardMedia
          component="iframe"
          alt={service.landingPageURL}
          height="140"
          src={service.landingPageURL}
          title={service.title}
        />
      </CardActionArea>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Typography gutterBottom variant="h5" component="h2">
              {service.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {service.description}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" my={-2}>
            <IconButton onClick={openLandingPage}>
              <LaunchIcon />
            </IconButton>
            <IconButton onClick={openDocs}>
              <MenuBookIcon />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

Service.propTypes = {
  service: PropTypes.object,
}

export default Service
