import React from 'react'
import Grid from '@material-ui/core/Grid'
import Service from './service'
import PropTypes from 'prop-types'

function CenteredGrid({ services }) {
  const grids = services.map((service, index) => (
    <Grid item xs={12} md={3} key={index}>
      <Service service={service} />
    </Grid>
  ))
  return (
    <Grid container spacing={3}>
      {grids}
    </Grid>
  )
}

export default CenteredGrid

CenteredGrid.propTypes = {
  service: PropTypes.array,
}

CenteredGrid.defaultProps = {
  service: [],
}
